import { Routes } from '@angular/router';

export const rootRouterConfig: Routes = [
  { 
    path: '', 
    redirectTo: 'ru/main', 
    pathMatch: 'full' 
  },
  { 
    path: 'ru', 
    loadChildren: './views/ru/home.module#HomeModule'
  },
  { 
    path: 'en', 
    loadChildren: './views/en/home.module#HomeModule'
  },
  { 
    path: '**', 
    redirectTo: 'ru/main'
  }
];

